@import "src/styles/helpers"

.chart
  margin-top: 24px
  font-size: 12px
  position: relative

.grid
  stroke: $n2
  +dark
    stroke: $n6

%area > path
  fill: #f3f5ff
  +dark
    fill: $n6

.referenceArea
  @extend %area
  fill: $n4
  +dark
    fill: $n2
