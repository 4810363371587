@import ../../../styles/helpers

.wrapper
    position: relative
    display: flex
    justify-content: space-between
    align-items: center
    height: 60px
    margin-bottom: 12px
    padding: 0 24px
    box-shadow: inset 0 0 0 2px $n3
    border-radius: 12px
    transition: all 0.2s
    &:hover
        box-shadow: inset 0 0 0 2px $shades1
    +dark
        box-shadow: inset 0 0 0 2px $n6
        background: none
        color: $n4
        &:hover
            box-shadow: inset 0 0 0 2px $n5
    &:last-child
        margin-bottom: 0
    &.childrens
        padding-right: 60px
        cursor: pointer
        &:before
            content: ""
            position: absolute
            top: 0
            right: 12px
            bottom: 0
            width: 48px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
            transition: transform .2s
    &.active
        &:before
            transform: rotate(180deg)

.children
    margin-left: 24px
    cursor: initial
    &:hover
        box-shadow: inset 0 0 0 2px $n3
    +dark
        &:hover
            box-shadow: inset 0 0 0 2px $n6
