@import ./src/styles/helpers

$paginationColor: $n4

.pagination
  margin: 12px 48px 0 0
  display: flex
  align-items: center
  justify-content: flex-end
  column-gap: 24px

.paginationInfo
  color: $paginationColor
  font-weight: 500
  font-size: 16px
  line-height: 24px

.paginationButtons
  display: flex
  column-gap: 8px

.paginationButton
  border: 1px solid $n3
  border-radius: 50%
  width: 40px
  height: 40px
  transition: .2s

  &:hover
    & > .arrow
      fill: $n8

  &:disabled
    pointer-events: none
    border-color: transparent
    opacity: 0.3

.arrow
  transition: .2s
  fill: $paginationColor

.arrowReversed
  transform: rotate(180deg)
