@import "src/styles/helpers"

.wrap
  display: flex
  flex-direction: column
  justify-content: center
  max-width: 412px
  min-height: 274px
  margin: 0 auto

.title
  margin-bottom: 20px
  font-weight: 600
  font-size: 18px
  line-height: 24px
  text-align: center

.button
  margin: 0 auto
