@import ../../../styles/helpers

.profile
    display: flex
    align-items: center
    margin-bottom: 32px
    +t
        margin-bottom: 24px
    & > .button
        +m
            display: none

.avatar
    position: relative
    flex-shrink: 0
    width: 96px
    height: 96px
    margin-right: 32px
    +m
        width: 80px
        height: 80px
        margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.remove
    display: none
    position: absolute
    top: 0
    right: 0
    width: 24px
    height: 24px
    border-radius: 50%
    background: $n1
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25)
    font-size: 0
    +m
        display: inline-block

.file
    position: relative
    display: inline-block
    margin-right: 12px
    overflow: hidden
    +m
        margin-right: 0
    input
        position: absolute
        top: 0
        left: 0
        font-size: 160px
        opacity: 0
    .button
        +m
            padding: 0 10px

.field
    &:not(:last-child)
        margin-bottom: 32px
        +t
            margin-bottom: 24px