@import src/styles/helpers

.tabs
  display: flex
  align-items: flex-start
  gap: 8px

.tab
  display: flex
  align-items: center
  height: 48px
  padding: 8px 16px
  border-radius: 12px
  font-size: 15px
  font-weight: 600
  white-space: nowrap
  color: $n4
  background: $n1
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  transition-property: background, box-shadow, color
  transition-duration: .2s
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .05), inset 0 1px 1px #ffffff
  +dark
    background: $n6
    box-shadow: inset 0px -2px 1px rgba($n8, 0.4), inset 0px 1px 1px rgba($n, 0.11)

  &:hover
    color: $n8
    +dark
      color: $n1

.active
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, .05), inset 0 1px 1px $n3
  color: $n8
  background: $n3
  +dark
    color: $n1
    background: $n5
    box-shadow: inset 0px -2px 1px rgba($n8, 0.4), inset 0px 1px 1px rgba($n, 0.11)