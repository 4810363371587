@import src/styles/helpers

.description
  margin-top: 24px
  padding: 24px 0 26px
  color: $n4
  border-top: 1px solid $n3

  & > *
    color: inherit
    font-size: 15px
    line-height: 24px

.descriptionText
  font-weight: 400

.buttons
  display: flex
  align-items: center
  gap: 24px
  margin-top: 34px
