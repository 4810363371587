@import "src/styles/helpers"

.card
    position: relative
    padding: 24px
    background: $n1
    border-radius: 8px
    +m
        padding: 16px
    +dark
        background: $n7

    &[aria-disabled='true']::after
        content: ''
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background: inherit
        z-index: 5
        border-radius: inherit
        opacity: 0.5
        +dark
            background: $n7

.head
    display: flex
    align-items: center
    min-height: 40px
    margin-bottom: 32px
    +m
        margin-bottom: 24px

.title
    margin-right: auto
    +m
        line-height: 40px

.marker
    width: 16px
    height: 32px
    border-radius: 4px
    margin-right: 16px

.blue
    background: $p1

.lightPurple
    background: $s2

.lightBlue
    background: $s3