@import "src/styles/helpers"

.totalRow
  color: $n8
  background: #b1e5fc !important

.total
  margin-top: 3px
  display: block

.totalCell
  font-weight: 700

.cell
  text-align: start
  vertical-align: middle
  white-space: nowrap

.forecastedCell
  opacity: .5

.colorBlue
  color: #0d96d0

.colorGreen
  color: #0b8e57

.colorRed
  color: #c92501
