@import "src/styles/helpers"

.notifications
  display: flex
  flex-direction: column
  gap: 12px
  position: fixed
  top: 12px
  left: 50%
  transform: translate(-50%, 0)
  z-index: 1000