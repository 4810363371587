.app
    padding: 40px
    text-align: center
.container
    margin: auto
    width: 50%
.ReactTags__tags
    min-height: 40px
    position: relative
    // padding: 0 0 5px
    background: $n2
    border-radius: 12px
    +dark-common
        background: $n6
.ReactTags__clearAll
    cursor: pointer
    height: 40px
    padding: 0 10px
    margin: 10px
    font-size: 14px
    border-radius: 12px
    background: $p3
    color: $n
    border: none
.ReactTags__tagInput
    border-radius: 2px
    display: inline-block
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus
    height: 40px
    margin: 0
    padding: 0 16px
    background: none
    box-shadow: none
    font-size: 14px
    font-weight: 600
    border: none
    min-width: 100px
    color: $n7
    +dark-common
        color: $n1
.ReactTags__tagInput input.ReactTags__tagInputField
    &::placeholder
        color: $shades1
        +dark-common
            color: $shades2
.ReactTags__editInput
    border-radius: 1px
.ReactTags__editTagInput
    display: inline-flex
.ReactTags__selected
    span
        &.ReactTags__tag
            display: inline-flex
            align-items: center
            margin: 5px 0 5px 10px
            padding: 2px 8px
            background: $s6
            border-radius: 6px
            font-size: 13px
            color: $n5
.ReactTags__suggestions
    position: absolute
    ul
        list-style-type: none
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2)
        background: $n
        width: 200px
        li
            &.ReactTags__activeSuggestion
                background: $s3
                cursor: pointer
    li
        border-bottom: 1px solid $n3
        padding: 5px 10px
        margin: 0
        mark
            text-decoration: underline
            background: none
            font-weight: 600
.ReactTags__remove
    display: flex
    width: 16px
    height: 16px
    margin-left: 4px
    cursor: pointer
    fill: $n4
    svg
        transition: transform 0.2s
        &:hover
            transform: rotate(90deg)
