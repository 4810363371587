@import "src/styles/helpers"

.modal
  width: 600px
  max-width: 600px

.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.image
  width: 460px
  height: 242px
  margin: 50px auto 40px
  border-radius: 20px

.title,
.finalTitle
  font-weight: 700
  font-size: 32px
  line-height: 40px
  letter-spacing: -0.01em
  color: $n9
  +dark
    color: $n2

.title
  margin-bottom: 13px

.text
  margin-bottom: 16px
  font-weight: 400
  font-size: 16px
  line-height: 26px
  letter-spacing: -0.01em
  color: black
  +dark
    color: $n

.mainText
  width: 416px
  height: 71px
  text-align: center

.buttons
  display: flex
  gap: 10px
  margin-bottom: 13px

.buttonPrev
  background: $n8 !important
  padding-right: 34px !important
  +dark
    background: $n4 !important

.buttonNext
  padding-left: 36px !important

.arrow,
.arrowReverse
  fill: transparent !important

.arrowReverse
  transform: rotate(180deg)

.emoji
  margin: 91px auto 63px
  font-weight: 700
  font-size: 100px
  line-height: 100px

.finalTitle
  margin-bottom: 23px

.finalText
  width: 456px

.list
  width: 406px
  margin-bottom: 30px
  list-style-type: disc
  font-size: 16px
  font-weight: 400
  line-height: 26px
  letter-spacing: -0.01em
  color: black
  +dark
    color: $n

.itemTitle,
.itemText
  display: inline

.itemTitle
  font-weight: 700

.buttonGo
  width: 119px

