.card
  min-height: 438px

.title
  font-weight: 600
  font-size: 20px
  line-height: 32px

.periodTitle
  height: 32px

.head
  margin-bottom: 28px

.total
  font-weight: 600
  font-size: 32px
  line-height: 40px
