@import "./src/styles/helpers"

.notification
  display: flex
  align-items: center
  column-gap: 10px
  padding: 8px 24px
  font-size: 16px
  max-width: 400px
  width: 100%
  font-weight: 600
  border-radius: 24px
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .08)
  text-align: center
  animation: showContent .5s

.icon
  flex-shrink: 0

.success
  background: #D6F5D8
  color: #29A32F

.error
  background: red
  color: $n1

.warning
  background: yellow
  color: $n1

@keyframes showContent
  from
    opacity: 0
  to
    opacity: 1
