@import ../../styles/helpers

.outer
    max-width: 550px

.tabs
    margin: 26px 0

.btns
    margin-top: 24px
    display: flex
    gap: 12px

.field
    margin-bottom: 24px

.border
    border-bottom: 1px solid $n3

    &.invalid
        border-color: red

.inputContainer
    border-color: transparent !important
    margin-bottom: initial !important

.amountExchange
    display: flex
    justify-content: center
    margin: -8px 0 8px 0
    font-size: 14px
    line-height: 20px
    font-weight: normal
    color: $n4
