@import ../../../styles/helpers

.head
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $n3
    +dark
        border-color: $n6

.title
    position: 40px

.field
    position: relative
    margin-bottom: 24px

.icon
    position: absolute
    top: 0
    left: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    width: 40px
    pointer-events: none
    svg
        fill: $n4

.button
    cursor: pointer

.btns
    display: flex
    margin: 0 -8px
    padding-top: 24px
    border-top: 1px solid $n3
    +dark
        border-color: $n6
    .button
        flex-grow: 1
        margin: 0 8px
