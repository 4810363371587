@import ../../../styles/helpers

.item
    display: flex
    align-items: center
    justify-content: center
    padding: 12px 24px
    flex: 0 0 33.33%
    border-radius: 12px
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: all .2s
    +m
        display: block

.icon
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    margin-right: 16px
    border-radius: 50%
    +m
        display: none

.subtitle
    display: flex
    align-items: center
