@import ../../styles/helpers

.wrapper
  border-radius: 0 0 8px 8px
  background: $n
  +m
    margin: 0 -16px
  +dark
    background: transparent

.table
  display: table
  width: 100%
  +t
    display: block

.col
  @include tableColumn
  color: $n5
  font-weight: 500

.row
  @include tableRow

.userRow
  cursor: pointer

.input
  width: 400px

.actions
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px
  border-radius: 8px 8px 0 0
  background: white

.selectContainer
  display: flex
  align-items: center

.label
  margin-right: 16px

.select
  width: 85px
