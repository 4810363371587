@import ../../styles/helpers

.item
    fill: $n4
    color: $n4
    +dark
        fill: $n4
        color: $n4
    &.active
        fill: $n7
        color: $n7
        background: $n1
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        +dark
            fill: $n1
            color: $n1
            background: $n6
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06)

.button
    width: 100%
    margin: 12px 0
    &:last-child
        margin-bottom: 0
