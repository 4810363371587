@import src/styles/helpers

.percent
  border-radius: 4px
  padding: 4px

.growth
  background: #D6F5D8
  color: #29A32F

.loss
  color: #CA0602
  background: #FECECD

.period
  color: $n4
  font-size: 12px
  line-height: 16px
  font-weight: 700
  margin-top: 4px