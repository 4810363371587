@import "src/styles/helpers"

.slider
  display: flex
  gap: 5px
  margin-bottom: 15px
  cursor: pointer

.dot
  width: 10px
  height: 10px
  border-radius: 10px
  background: $n3_1
  transition: all .2s

.activeDot
  width: 25px
  background: $n8
  +dark
    background: $n4
