@import "src/styles/helpers"

.wrap
  position: relative
  overflow-x: auto
  overflow-y: hidden
  margin: 0 -24px

  &::-webkit-scrollbar
    height: 6px

    &-thumb
      border-radius: 10px
      background: $n2
      +dark
        background: $n6

.table
  font-size: 12px
  line-height: 12px

  & * th, td
    padding: 10px 15px

  & * th:first-child, td:first-child
    position: sticky
    top: 0
    left: 0
    z-index: 1

    background: $n1
    +dark
      background: $n7
