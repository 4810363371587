@import ../../styles/helpers

.table
  display: flex
  flex-direction: column
  width: 400px
  gap: 16px
  padding: 16px
  border-radius: 8px
  background: $n

.row
  display: flex
  justify-content: space-between
