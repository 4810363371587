.container
  max-width: 1280px
  width: 100%
  margin: 0 auto
  padding: 40px 20px
  overflow: auto


.nav
  margin-bottom: 20px
