@import "src/styles/helpers"

%neighbor
  margin-bottom: 32px

.content
  width: 100%
  height: 100vh
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  max-width: 210px
  margin: 0 auto

.iconWrap
  width: 128px
  height: 128px
  background: #e4ceb5
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  @extend %neighbor

.icon
  transform: scale(2.5)

.text
  text-align: center
  @extend %neighbor

.title
  font-weight: 600
  font-size: 32px
  line-height: 45px
  margin-bottom: 13px

.description
  font-weight: 400
  font-size: 20px
  line-height: 28px

.btn
  width: 100%
