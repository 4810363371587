@import ../../styles/helpers

.container
    position: relative

.close
    position: absolute
    top: 15px
    right: 15px
    display: flex
    align-items: center
    justify-content: center
    width: 36px
    height: 36px
    border-radius: 50%
    background: $n
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06)
    font-size: 0
    svg
        fill: $n5
        transition: transform .2s
    +dark
        background: $n7
        svg
            fill: $n1
    &:hover
        svg
            transform: rotate(90deg)

.login
    display: flex
    max-width: 720px
    justify-content: center
    padding: 100px 70px 48px
    background: $n1
    +m
        padding: 24px
    +dark
        background: $n7

.wrapper
    max-width: 516px
    width: 100%
    +m
        max-width: 100%

.logo
    display: inline-block
    width: 85px
    margin-bottom: 32px
    img
        width: 100%

.title
    margin-bottom: 32px

.head
    margin-bottom: 24px

.subtitle
    margin-bottom: 24px
    +body2-s

.btns
    display: flex
    margin: 0 -4px
    .button
        width: 100%
        // flex: 0 0 calc(50% - 8px)
        // width: calc(50% - 8px)
        margin: 0 4px
        padding: 0 16px
        img
            margin-right: 10px

.body
    .button
        width: 100%

.field
    margin-bottom: 24px

.info
    margin-top: 24px
    text-align: center
    +caption1
    color: $n4
    a
        font-weight: 700
        color: $n7
        transition: color .2s
        +dark
            color: $n1
        &:hover
            color: $p1
.note
    max-width: 265px
    margin-bottom: 24px
    +body2-s
    color: $shades1
