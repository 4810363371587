@import src/styles/helpers

.calendarButton
  position: relative
  padding: 0 48px 0 16px !important
  background: $n !important
  box-shadow: inset 0 0 0 2px $n3
  +dark
    box-shadow: inset 0 0 0 2px $n6
    background: transparent !important
    color: $n4 !important

  &:hover
    box-shadow: inset 0 0 0 2px $shades1 !important
    background: transparent !important
    +dark
      box-shadow: inset 0 0 0 2px $n5 !important

  &:before
    content: ""
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 48px
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
    transition: rotate .2s

.active
  box-shadow: inset 0 0 0 2px $shades1 !important
  +dark
    box-shadow: inset 0 0 0 2px $n5 !important
    color: $n1 !important
  &:hover
    background: $n !important
    +dark
      background: transparent !important
      box-shadow: inset 0 0 0 2px $n5

.active.calendarButton:before
  rotate: 180deg

.range
  position: absolute
  right: 0
  top: calc(100% + 10px)

.activePeriod
  background: $p1
  color: $n
