@import ../../../styles/helpers

.wrapper
  position: absolute
  width: 400px
  left: calc(100% + 8px)
  padding: 24px
  background: $n1
  border-radius: 16px
  box-shadow: 0px 0px 14px -4px rgb(0 0 0 / 5%), 0px 32px 48px -8px rgb(0 0 0 / 10%)
  z-index: 3
  +dark
    background: $n7
