@import src/styles/helpers

.search
  display: flex
  column-gap: 15px
  align-items: center
  background: $n2
  margin-bottom: 4px
  +dark
    background: $n7

  & > svg
    fill: $n4

  & > input
    background: inherit
    width: 100%
    +base2
    +dark
      color: $n1
    +placeholder
      padding-left: 2px
      color: #9A9FA5