// media queries
=w
    @media only screen and (max-width: "1419px")
        @content
=x
    @media only screen and (max-width: "1339px")
        @content
=d
    @media only screen and (max-width: "1259px")
        @content
=t
    @media only screen and (max-width: "1023px")
        @content
=m
    @media only screen and (max-width: "767px")
        @content
=a
    @media only screen and (max-width: "639px")
        @content
=s
    @media only screen and (max-width: "474px")
        @content

// fonts
=inter
    font-family: 'Inter', sans-serif

// colors
$p1: #2A85FF
$p2: #83BF6E
$p3: #FF6A55
$p4: #8E59FF
$s1: #FFBC99
$s2: #CABDFF
$s3: #B1E5FC
$s4: #B5E4CA
$s5: #FFD88D
$s6: #C6E8FB
$s7: #B6E3ED

$n: #FFFFFF
$n1: #FCFCFC
$n2: #F4F4F4
$n3: #EFEFEF
$n3_1: #CDCDCD
$n4: #6F767E
$n5: #33383F
$n6: #272B30
$n7: #1A1D1F
$n8: #111315
$n9: #1A1A1A

$shades1: #9A9FA5
$shades2: #6F767E
$shades3: rgba(#6F767E, .4)
$shades4: rgba(#111315, .5)

$l1: #333333
$success: #29A32F

// typography
=title1-s
    font-size: 20px
    font-weight: 600
    line-height: calc(32/20)
    letter-spacing: -.02em
    +m
        font-size: 18px

=title1-m
    font-size: 20px
    font-weight: 500
    line-height: calc(32/20)
    letter-spacing: -.02em
    +m
        font-size: 18px

=title2
    font-size: 18px
    line-height: calc(24/18)
    letter-spacing: -0.02em

=base1-s
    font-size: 15px
    font-weight: 600
    line-height: calc(24/15)

=base1-b
    font-size: 15px
    font-weight: 700
    line-height: calc(24/15)

=base2
    font-size: 14px
    font-weight: 600
    line-height: calc(24/14)

=body1-m
    font-size: 15px
    font-weight: 500
    line-height: calc(24/15)
    letter-spacing: -.015em

=body1-s
    font-size: 15px
    font-weight: 600
    line-height: calc(24/15)
    letter-spacing: -.015em

=body2-s
    font-size: 14px
    font-weight: 600
    line-height: calc(24/14)

=caption1
    font-size: 13px
    font-weight: 600
    line-height: calc(16/13)

=caption1-m
    font-size: 13px
    font-weight: 500
    line-height: calc(16/13)

=caption2
    font-size: 12px
    font-weight: 700
    line-height: calc(16/12)

=caption2-m
    font-size: 12px
    font-weight: 500
    line-height: 1

=button1
    font-size: 15px
    font-weight: 700
    line-height: calc(24/15)

=button2
    font-size: 13px
    font-weight: 700
    line-height: calc(24/13)

//triangle
=arr($width, $height, $bg, $direction)
    width: 0px
    height: 0px
    border-style: solid
    @if $direction == t
        border-width: 0 $width / 2 + px $height + px $width / 2 + px
        border-color: transparent transparent $bg transparent
    @if $direction == r
        border-width: $height / 2 + px 0 $height / 2 + px $width + px
        border-color: transparent transparent transparent $bg
    @if $direction == b
        border-width: $height + px $width / 2 + px 0 $width / 2 + px
        border-color: $bg transparent transparent transparent
    @if $direction == l
        border-width: $height / 2 + px $width + px $height / 2 + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == tl
        border-width: $height + px $width + px 0 0
        border-color: $bg transparent transparent transparent
    @if $direction == tr
        border-width: 0 $width + px $height + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == br
        border-width: 0 0 $height + px $width + px
        border-color: transparent transparent $bg transparent
    @if $direction == bl
        border-width: $width + px 0 0 $height + px
        border-color: transparent transparent transparent $bg
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
=placeholder
    &::placeholder
        @content

// media query width
=r($width)
    @media only screen and (max-width: $width+ "px")
        @content

=rmin($width)
    @media only screen and (min-width: $width+ "px")
        @content

// theme
=dark
    @at-root :global(.dark-mode) &
        @content

=dark-body
    @at-root .dark-mode
        @content

=dark-common
    @at-root .dark-mode &
        @content

@mixin tableColumn
        display: table-cell
        vertical-align: middle
        padding: 10px 16px
        +base2
        +t
            display: flex
            align-items: center
            padding: 0

        &:first-child
            padding-left: 24px
            +t
                padding-left: 0

        &:nth-child(2)
            +t
                align-items: flex-start
                order: -1
                padding-bottom: 12px
                border-bottom: 1px solid $n3
                +dark
                    border-color: $n6

        &:nth-child(3)
            +t
                display: none

        &:last-child
            padding-right: 24px
            +t
                padding-right: 0

        &:not(:last-child)
            +t
                margin-bottom: 12px

@mixin tableRow
  position: relative
  display: table-row
  +t
    display: flex
    flex-direction: column
    padding: 16px 24px
  +m
    padding: 16px

  &:hover
    .actions
      display: flex

  &:first-child
    +t
      display: none

    .col
      // display: flex
      // align-items: center
      +caption2
      font-weight: 500
      color: $n4

  &:nth-child(2n)
    +t
      background: rgba($n3, .35)
      +dark
        background: rgba($n6, .5)

    .col
      background: rgba($n3, .35)
      +t
        background: none
      +dark
        background: rgba($n6, .5)
        +t
          background: none
