@import ../../styles/helpers

.timeFrameHead
  min-width: 150px
  background-color: #fff

.timeFrameMenu
  position: absolute
  right: -50px
  top: calc(100% + 10px)
  left: initial

.range
  position: absolute
  right: 205px
  top: calc(100% + 10px)
