@import "src/styles/helpers"

.wrap
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 30px

.description
  display: flex
  color: $l1

.icon
  margin-right: 12px
  fill: $n4

.dropdown
  width: 142px

.dropdownMenu
  left: initial !important
