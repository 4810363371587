.wrap
  display: flex
  flex-direction: column
  row-gap: 15px
  margin-bottom: 25px

.field
  display: flex
  justify-content: space-between
  align-items: center

.calendar
  position: relative

.disabled
  opacity: .5
  pointer-events: none
