@import "src/styles/helpers"

.card
  min-height: 374px
  display: flex
  flex-direction: column
  margin-bottom: 20px

.actions
  display: flex
  column-gap: 12px
  position: relative

.button
  svg
    fill: white
    margin-left: 0 !important

.title
  font-weight: 600
  font-size: 20px
  line-height: 32px

.periodTitle
  height: 32px

.head
  margin-bottom: 36px
