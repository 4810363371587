@import "src/styles/helpers"

.chart
  margin-top: 24px
  font-size: 12px

.grid
  stroke: $n2
  +dark
    stroke: $n6
