@import "src/styles/helpers"

.title
  margin-bottom: 26px

.description
  font-weight: 400
  font-size: 20px
  line-height: 32px

.description, .textInput
  margin-bottom: 12px

.input
  padding: 12px
  +placeholder
    font-weight: 400
    font-size: 14px
    line-height: 24px

.btn
  width: 100%

  &:disabled
    background: $n5
    opacity: .32
    pointer-events: none
