@import ../../styles/helpers

.switch
    display: inline-block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 0

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0
    &:checked + .inner .box
        background: $p1
        &:before
            transform: translate(24px,-50%)
            background: $n1

.inner
    position: relative
    display: inline-block
    transition: all .2s

.box
    position: relative
    display: block
    width: 48px
    height: 24px
    background: $n2
    border-radius: 12px 
    transition: all .2s
    +dark
        background: $n8
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 2px
        transform: translateY(-50%)
        width: 20px
        height: 20px
        border-radius: 50%
        background: $n1
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 2px 2px #FFFFFF, inset 0px -1px 1px rgba(0, 0, 0, 0.1)
        transition: all .2s 
        +dark
            background: $n6
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 1px 2px rgba(255, 255, 255, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.1)