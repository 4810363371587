@import ../../styles/helpers

.container
  display: flex
  flex-direction: column
  gap: 30px
  padding: 40px
  background: $n2

.wrapper
  width: 460px
  border: 1px solid $n6
  border-radius: 8px
  background: $n
  +m
    margin: 0 -16px
  +dark
    background: transparent

.table
  display: table
  width: 100%
  +t
    display: block

.col
  @include tableColumn
  font-weight: 500
  color: $n5

.row
  @include tableRow

  &:first-child
    .col
      +body2-s
      font-weight: 500
      color: $n5
    .title
      font-weight: 600

.title
  font-weight: 600

.backLink
  display: flex
  align-items: center
  width: fit-content
  column-gap: 10px
  padding: 4px 12px 4px 8px
  border: 1px solid $n6
  border-radius: 8px
  background: $n
  color: $n6

  &:hover, &:active
    background: $n3

.arrow
  transform: rotate(180deg)
