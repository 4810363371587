@import ../../../../styles/helpers

.item
    +d
        position: relative
    +m
        position: static
    &.active
        .head
            border-color: $p1
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05)
            svg
                fill: $n7
                +dark
                    fill: $n1

.head
    display: flex
    padding: 14px
    border-radius: 12px
    border: 2px solid $n3
    cursor: pointer
    transition: all .2s
    align-items: center
    justify-content: center

    +dark
        border-color: $n6
    &:hover
        border-color: $p1
