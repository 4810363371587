@import ../../styles/helpers

.page
    padding: 0 0 0 340px
    animation: appearance 0.8s
    +x
        padding: 0 0 0 300px
    +d
        padding-left: 96px
    +t
        overflow: hidden
    +m
        padding-left: 0

.sidebar
    +m
        transform: translateX(-100%)
        transition: transform .3s
        &.visible
            transform: translateX(0)

.inner
    display: flex
    flex-direction: column
    min-height: calc(100vh - 96px)
    padding: 40px
    +x
        padding: 32px 24px
    +m
        padding: 24px 16px

.container
    display: flex
    flex-direction: column
    flex-grow: 1
    width: 100%
    max-width: 1200px
    margin: 0 auto
    &.wide
        max-width: 100%

@keyframes appearance
    0%
        opacity: 0
    100%
        opacity: 1

.header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 24px
    +m
        margin-bottom: 16px

.burger
    display: none
    flex-direction: column
    justify-content: center
    align-items: center
    width: 48px
    height: 48px
    +m
        display: flex
    &:before,
    &:after
        content: ""
        width: 16px
        height: 2px
        border-radius: 2px
        background: $n4
    &:before
        margin-bottom: 6px

.actions
    display: flex
    column-gap: 12px
    position: relative

.button
    svg
        fill: white
        margin-left: 0 !important
