@import ../../../styles/helpers

.container
  display: flex
  align-items: baseline
  justify-content: center
  margin: 24px 0
  border-bottom: 1px solid $n3
  +dark
    border-color: $n6

.currency
  margin-right: 8px
  font-size: 18px
  color: #808080

.span
  position: absolute
  opacity: 0
  font-size: 24px
  top: -1000px

.input
  width: auto
  height: 48px
  font-size: 24px
  text-align: center
  background-color: transparent
  color: $n7
  +dark
    color: $n1
  +placeholder
    color: $n4
    +dark
      color: $shades1

.invalid
  color: red
