@import '../../styles/helpers'

.period
  font-weight: normal
  font-size: 16px
  line-height: 24px
  color: $n4
  margin-top: 12px

.small
  position: relative
  top: -10px
  font-size: 13px
