@import ../../../styles/helpers

.wrapper
    position: relative
    display: flex
    align-items: center
    height: 48px
    margin-bottom: 4px
    padding: 0 12px
    border-radius: 12px
    transition: all 0.2s
    cursor: pointer
    color: $n4
    &:hover
        background: $n3
        color: $n7
        .action
            display: block
    +dark
        color: $n4
        &:hover
            background: $n6
    &:last-child
        margin-bottom: 0
    &.childrens
        padding-right: 48px
        .arrow
            content: ""
            position: absolute
            top: 0
            right: 0
            bottom: 0
            width: 48px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
            transition: transform .2s
    &.active
        .arrow
            transform: rotate(180deg)

.action
    display: none
.actionHead
    background-color: transparent
.actionBody
    top: calc(100% + 6px)

.content
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

.children
    padding-left: 36px
    &:hover
        box-shadow: inset 0 0 0 2px $n3
    +dark
        &:hover
            box-shadow: inset 0 0 0 2px $n6
