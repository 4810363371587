@import "src/styles/helpers"

.card
  scroll-margin-top: 20px

.head
  +m
    flex-wrap: wrap
    .title
      width: 100%
      flex-grow: 1
      margin: 0 0 16px

.title
  font-weight: 600
  font-size: 20px
  line-height: 32px

.periodTitle
  height: 32px

.reloadButton
  padding: 0 8px

.button
  margin-left: 16px
  +m
    flex-grow: 1

.wrapper
  margin: 0 -24px
  background: $n1
  +m
    margin: 0 -16px
  +dark
    background: transparent

.table
  display: table
  width: 100%
  +t
    display: block

.col
  @include tableColumn

.row
  @include tableRow

.negative
  color: $p3

.foot
  padding: 12px 0
  text-align: center

  .button
    .loader
      transform: scale(.8)
      margin: 0 18px 0 8px
