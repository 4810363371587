@import src/styles/helpers

.date
  width: max-content
  padding: 24px
  z-index: 100
  background-color: $n
  border-radius: 32px
  box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgb(244, 244, 244, .1)

  +dark
    background-color: $n7
    box-shadow: inset 0 0 0 2px $n5, 0 4px 12px rgb(17, 19, 21, .1)

.calendar
  [class*='react-datepicker__navigation']
    width: 36px
    height: 36px
    top: 0
    transition: background-color 0.2s
    border-radius: 100%

    &:hover
      background: #efefef


  [class*='react-datepicker__header']
    padding: 0 !important

  [class*='react-datepicker__year']
    margin: initial

  [class*='react-datepicker-year-header']
    font-size: 20px

  [class*='react-datepicker__monthPicker'], [class*='react-datepicker__year-wrapper']
    border-top: 1px solid $n3
    border-bottom: 1px solid $n3
    padding: 24px 0
    margin: 26px 0 24px

    +dark
      border-top-color: $n5
      border-bottom-color: $n5

  [class*='react-datepicker__monthPicker'] > div:not(:last-child)
      margin-bottom: 35px

  [class*='react-datepicker__month-text'], [class*='react-datepicker__year-text']
    border-radius: 40px
    padding: 5px
    width: 46px
    height: 30px

  [class*='react-datepicker__month-text']:not(:last-child)
    margin-right: 38px

  [class*='react-datepicker__year-text']
    &:hover
      border-radius: 40px

    &:not(:nth-child(4n+4))
      margin-right: 6px

    &:not(:nth-last-child(-n+4))
      margin-bottom: 25px

  [aria-disabled='true']
    pointer-events: none
    opacity: .4

  [aria-current]
    background-color: $n
    color: $n8

    &:hover
      background-color: $n3

  [class*='react-datepicker__month--in-range'], [class*='react-datepicker__year--in-range']
    background-color: #CCE5FF !important
    color: $p1 !important

  [class*='react-datepicker__month--range-start'], [class*='react-datepicker__year--range-start']
    background-color: $p1
    color: $n
    +dark
      background-color: $p1
      color: $n !important

  [class*='react-datepicker__year-text--today']
    &:not([class*='react-datepicker__year-text--selected'])
      background: transparent
      color: $n8
      font-weight: 800
      +dark
        color: $n

  [class*='react-datepicker__month--range-end'], [class*='react-datepicker__year--range-end']
    background-color: $p1 !important
    color: $n !important

  [class*='react-datepicker__year-wrapper']
    max-width: 285px
    //max-width: 297px

.foot
  display: flex
  column-gap: 16px

.button
  max-width: 126px
  width: 100%

  &:last-child
    max-width: 108px
