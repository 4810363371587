@import '../../../styles/helpers'

.col
  @include tableColumn

.row
  @include tableRow

.rowOpacity
  opacity: 0.5

.type
  flex-shrink: 0
  white-space: nowrap
  font-size: 14px
  line-height: 28px
  font-weight: 600

.category
  display: inline-block
  padding: 2px 8px
  border-radius: 6px
  font-size: 14px
  font-weight: 600
  background-color: $s7
  color: $n5

.status
  display: inline-block
  padding: 2px 8px
  border-radius: 6px
  font-size: 14px
  font-weight: 600
  background-color: $s4
  color: $n5

.statusWaiting
  @extend .status
  background-color: $n3

.date
  white-space: nowrap
  font-size: 14px
  font-weight: 600
  color: $n4

.cell
  display: flex
  align-items: center
  height: 100%

.tags
// max-width: 500px
// overflow: hidden
// white-space: nowrap
// text-overflow: ellipsis
// width: 100%

.tag
  display: inline-block
  margin-right: 8px
  border-radius: 6px
  padding: 2px 8px
  background: $s6
  color: $n5

.amountCell
  flex-direction: column
  align-items: initial

.amount
  line-height: 16px

.rate
  font-size: 10px
  line-height: 12px
  font-weight: 600
  color: $n4

.actionsCell
  display: table-cell
  position: relative
  width: 100px

.actions
  display: none
  position: absolute
  top: 0
  right: 0
  bottom: 0
  gap: 16px
  padding-right: 20px
  background: linear-gradient(90deg, transparent 0, #E6E6E6 50%)
  justify-content: flex-end
  fill: $n4
  +dark
    background: linear-gradient(90deg, transparent 0, #37373780 50%)

  button
    cursor: pointer
    transition: fill .2s

    &:hover
      fill: $n8
