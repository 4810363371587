@import "src/styles/helpers"

.wrap
  position: relative

.switch
  position: absolute !important
  right: 0

.linkInput
  margin-bottom: 12px
  width: 100%

.btn
  width: 100%

  &:disabled
    background: $l1
    opacity: 0.32
    pointer-events: none

  &.success, &.success:hover
    background: $success
