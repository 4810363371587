@import ../../../styles/helpers

.tooltip
  background: $n5
  border-radius: 8px
  border: 1px solid $n6
  box-shadow: 0 0 0 1px #000000
  color: $n
  padding: 8px

.cash
  font-size: 20px

.content
  display: flex
  flex-direction: column

.line
  display: flex
  flex-direction: row
  align-items: center
  flex-shrink: 0
  column-gap: 4px

.legend
  display: block
  width: 12px
  height: 12px
  border-radius: 4px
