@import "../../../../styles/helpers"

.head
  font-weight: 500
  white-space: nowrap
  text-align: start

  &:first-child
    min-width: 117px

.type
  color: $n4
  margin-top: 5px
