@import src/styles/helpers

.tooltip
  position: relative
  top: -1px

  svg
    fill: $shades1

.activityIcon
  fill: $n5

.chartIcon
  fill: $p2

.dataBaseIcon
  fill: #CA0602