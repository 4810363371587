.wrap
  display: flex
  justify-content: space-between
  margin-bottom: 36px

.passwordInput
  padding-right: 8px
  width: 100%

.switch
  margin-bottom: 14px

.actions
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-end

.btn:disabled
  opacity: 0.5
  pointer-events: none
